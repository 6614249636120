.userOrders_tab {
  height: 100%;
  font-size: 12px;
}

.userOrders_nav {
  padding-left: 10px;
  display: flex;
  padding-bottom: 10px;
  button {
    text-align: start;
    padding-right: 20px;
    cursor: pointer;
    color: var(--secondary-text);
    &.active {
      color: var(--primary-text);
    }
  }
}

.orders_cont {
  height: 86%;
}

.cancel_btn {
  width: 15px;
  height: 15px;
  border-radius: 5px;
  background-color: var(--sell-text);
  padding: 0;
  font-weight: 900;
  line-height: 10px;
  opacity: 0.9;
  &:hover {
    opacity: 1;
  }
}

.order_filter {
  width: fit-content;
  height: 24px;
  padding: 0 9px;
  position: absolute;
  top: 11px;
  right: 60px;
  font-size: 11px;
  border: 1px solid var(--input-border-default);
  border-radius: 16px;
  color: var(--secondary-text);
  cursor: pointer;
  .active {
    color: var(--primary-text);
  }
  & span:first-child {
    display: inline-block;
    height: 100%;
    padding-right: 6px;
    line-height: 24px;
    border-right: 1px solid var(--input-border-default);
  }
  & span:not(:first-child) {
    padding-left: 8px;
  }

  @media (max-width:1020px) {
    right: 16px;
    top: 34px;
  }
}