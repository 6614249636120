@import "../../../assets/_var.scss";

.mobile_nav {
  background: var(--module-background);
  border-top: solid 1px var(--input-background-hover);
  border-bottom: 1px solid var(--module-border);
  overflow: hidden;
  ul {
    display: flex;
    .mobile_nav_item {
      height: 36px;
      flex: 1;
      font-size: 15px;
      font-weight: 500;
      color: var(--primary-text);
      line-height: 35px;
      border-right: 1px solid var(--input-background-hover);
      &.nav_active {
        background: $baseGradient;
        color: var(--button-text);
      }
    }
  }
}

@media (max-width: 768px) {
  .mobile_nav_item {
    font-size: 13px !important;
    font-weight: 400 !important;
  }
}

@media (max-width: 387px) {
  .mobile_nav_item {
    line-height: 45px !important;
  }
}