@import "../../../assets/_var.scss";

.error_boundary_msg {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  opacity: 0.6;
}
