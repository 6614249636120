@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");

:root {
  --main-background: rgba(1, 1, 1, 1);
  --module-background: rgba(19, 23, 34, 1);
  --module-shadow: rgba(0, 0, 0, 0.8);
  --module-border: rgba(28, 33, 45, 1);
  --dropdown-shadow: rgba(154, 92, 253, 0.45);
  --button-border: $white;
  --button-text: $white;
  --button-disabled: rgba(111, 113, 118, 1);
  --primary-1:rgba(118, 63, 229, 1);
  --primary-2:rgba(249, 132, 146, 1);
  --primary-text: rgba(255, 255, 255, 1);
  --secondary-text: rgba(127, 127, 127, 1);
  --buy-text: rgba(82, 164, 154, 1);
  --buy-background: rgba(2, 199, 122, 0.25);
  --sell-text: rgba(221, 94, 86, 1);
  --sell-background: rgba(255, 59, 105, 0.25);
  --active-or-hover-element: rgba(230, 136, 150, 1);
  --hover-element-default: rgba(185, 163, 238, 1);
  --input-background-default: rgba(13, 15, 21, 1);
  --input-background-hover: rgba(28, 33, 45, 1);
  --input-background-disabled: rgba(19, 23, 34, 1);//25, 29, 41, 1
  --input-border-default: rgba(28, 33, 45, 1);
  --input-border-disabled: rgba(76, 85, 102, 1);
  --input-border-hover: rgba(13, 15, 21, 1); 
  --input-border-active: rgba(185, 163, 238, 1);
  --pair-active-row: rgba(28, 33, 45, 1);
}

$white: #fff;
$baseGradient: transparent linear-gradient(97deg, var(--primary-1) 0%, var(--primary-2) 100%);
$textBlue: #4d6198;
$scrollColor: #374462;
