@import "../../../../assets/_var.scss";

.pair_tab {
  height: 100%;
  font-size: 13px;
  .search_cont {
    margin: 0 10px 10px;
    width: calc(100% - 20px) !important;
    &::before {
      top: 10px;
    }
    .search {
      height: 35px !important;
    }
  }
}

.pair_title {
  text-align: start;
  font-size: 14px;
  font-weight: 400;
  padding: 15px;
}

.fltter_cont {
  display: flex;
  margin: 0 13px 10px;
  gap: 5px;
  align-items: center;
  .filter {
    color: var(--secondary-text);
    cursor: pointer;
    &:hover {
      color: var(--primary-text);
    }
    &.active {
      color: var(--primary-text);
    }
  }
}

.pair_fav {
  cursor: pointer;
  position: relative;
  width: 14px;
  height: 14px;
  background: url(../../../../assets/favorite_star.svg) no-repeat center;
}
.pair_fav_selected {
  cursor: pointer;
  position: relative;
  width: 14px;
  height: 14px;
  background: url(../../../../assets/favorite_checked.svg) no-repeat center;
}

.pair_cont {
  border-top: 1px solid var(--input-border-default);
  .sort_cont {
    display: flex;
    border-bottom: 1px solid var(--input-border-default);
    padding: 0 15px 0 10px;
    color: var(--secondary-text);
    .sort_item {
      font-size: 11px;
      padding: 7px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .input_btns {
        height: 14px;
        width: 20px;
        .trade_arrow_down {
          width: 5px;
        }
      }
    }
  }
  .pair_row {
    padding: 5px 15px;
    color: var(--secondary-text);
    font-weight: 400;
    text-align: start;
    &:hover {
      background-color: var(--input-background-hover);
    }
  }
  .pair_row_active {
    background-color: var(--pair-active-row);
  }
}