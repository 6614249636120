@import "../../../../assets/_var.scss";

.asset_wrapper {
  width: calc(100% - 30px);
  min-height: 75px;
  max-height: 150px;
  border-radius: 24px;
  margin-bottom: 16px;
  padding: 10px 20px 0 10px;
  background: var(--input-background-default);
  border: 1px solid var(--module-border);
  color: var(--primary-text);

  &.blur {
    filter: blur(4px);
    backface-visibility: hidden;
    perspective: 1000;
    transform: translate3d(0, 0, 0);
    transform: translateZ(0);
  }

  .asset_balance {
    display: flex;
    justify-content: flex-end;
    color: var(--secondary-text);
    font-weight: 300;
    margin-bottom: 10px;
    .balance_cont {
      color: var(--primary-text);
      font-weight: 400;
      margin-left: 5px;
    }
  }

  .asset_item {
    display: flex;
    justify-content: space-between;

    .asset_name {
      min-width: 150px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 5px;
      cursor: pointer;
      &:disabled {
        pointer-events: none;
        cursor: default !important;
        &:hover {
          background: transparent !important;
        }
      }
      &:hover {
        min-width: 130px;
        background: var( --input-background-hover);
        border-radius: 12px;
        .arrow_down {
          background: url(../../../../assets/down_arrow_hover.svg) no-repeat center;
        }
      }

      .asset_txt_big {
        display: inline-block;
        max-height: 62px;
        overflow-y: auto;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        text-align: start;
        border: none;
        background: transparent;
        color: var(--primary-text);
        cursor: pointer;
      }

      .asset_txt_small {
        margin: 0;
        color: var(--secondary-text);
        cursor: pointer;
      }

    }

    .asset_choose {
      align-items: flex-end;
      padding-top: 5px;
      padding-right: 10px;

      .choose_inp_wrapper {
        display: flex;
        transform: translateX(10px);
        height: 20px;
      }

      .choose_txt {
        position: relative;
        right: -10px;
        color: var(--primary-text);
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        text-align: end;
        cursor: default;
      }
    }
  }
}
