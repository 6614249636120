@import "../../../../assets/_var.scss";

.setting_cont {
  margin-left: 10px;
  @media (max-width: 1020px) {
    display: none;
  }
}

.select_sett {
  position: absolute;
  top: 8px;
  right: 31px;
  width: 22px;
  height: 20px;
  background: url(../../../../assets/settingIcon.svg) center no-repeat;
}

.setting_btn {
  width: 55px;
  height: 35px;
  margin-right: 15px;
  background: $baseGradient;
  border-radius: 15px;
  &:focus {
    outline: none;
  }
}

.filter_hide {
  display: none;
}

.filter_show {
  display: block;
  width: 180px;
  position: absolute;
  top: 41px;
  right: 10px;
  background: var(--module-background);
  padding: 10px;
  border-radius: 15px;
  z-index: 100;
  box-shadow: 0 4px 18px var(--dropdown-shadow);
  .filter_options {
    font-size: 12px;
    .settings_title {
      font-weight: 300;
      font-size: 14px;
      text-align: start;
      padding-bottom: 10px;
      border-bottom: 1px solid var(--input-border-default);
    }
    input {
      width: 13px;
      height: 12px;
      margin: 7px 0 0;
      &:checked {
        &::after {
        position: absolute;
        content: "";
        border-radius: 3px;
        border: 1px solid var(--primary-2);
        width: 12px;
        height: 12px;
        background-color: var(--primary-1);
        background-image: url(../../../../assets/checkbox.svg);
        background-repeat: no-repeat;
        background-position: center;
        }
      }
      &:focus {
        box-shadow: unset;
        outline: none;
      }
    }
    label {
      margin-left: 8px;
    }
    ul {
      margin-top: 10px;
      li {
        text-align: start;
      }
    }
    .lock_cont {
      margin-top: 12px;
      padding-top: 12px;
      border-top: 1px solid var(--input-border-default);
      text-align: start;
      label {
        position: relative;
        top: -2px;
      }
    }
    .reset_btn {
      margin-top: 10px;
      .layout_reset_btn {
        height: 27px;
        padding: 0 11px;
        background: $baseGradient;
        color: var(--button-text);
        border-radius: 6px;
        letter-spacing: 0.18px;
        font-size: 11px;
        &:disabled{
          opacity: 0.7;
        }
        &:hover {
          filter: grayscale(30%) brightness(1.2);
        }
      }
    }
  }
}