@import "../../../../assets/_var.scss";

@media (min-width: 1020px) {
  .total-balance-card{
    display: none;
  }
}

.total-balance-card {
  width: calc(100vw - 20px);
  height: 22px;
  margin: 5px 10px 0;
  padding: 4px 0;
  background-color: var(--module-background);
  border: 1px solid var(--module-border);
  border-radius: 16px;
  font-size: 12px;
  line-height: 22px;
  color: var(--secondary-text);
  span {
    padding: 0 2px;
  }
  .balance {
    color: var(--primary-text);
  }
}