@import "../../../../assets/_var.scss";

.OrderBook_tab {
  height: 100%;
  font-size: 11px;
}

.orderBook_options {
  display: flex;
  position: absolute;
  top: 12px;
  left: calc(100% - 170px);
  z-index: 5;
}

.OrderBook_cont {
  height: 95%;
}

.OrderBook_ALL_cont {
  height: 45%;
}

table {
  width: 100%;
  border-spacing: 0;
  th, td {
    text-align: start;
    padding-left: 15px;
    height: 23px !important;
    font-weight: 400;
    &:last-child{
      padding-right: 15px;
    }
  }
  thead {
    color: var(--secondary-text);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: var(--module-background);
    th {
      border-top: 1px solid var(--input-border-default) !important;
      border-bottom: 1px solid var(--input-border-default) !important;
    }
    span {
      color: var(--primary-text);
    }
  }
  td {
    border-top: 1px solid var(--module-background);
    span {
      display: inline-block;
      width: 50%;
    }
  }
  .green-row {
    cursor: pointer;
    &:hover {
      background-color: var(--buy-background) !important;
    }
  }
  .red-row {
    cursor: pointer;
    &:hover {
      background-color: var(--sell-background) !important;
    }
  }
}

.spase {
  width: 100%;
  height: 24px;
  border-bottom: 1px solid var(--input-border-default);
  font-size: 12px;
  text-align: center;
}

.center_spase {
  height: 26px !important;
  border-top: 1px solid var(--input-border-default);
  line-height: 30px;
}

.customSwitch {
  display: flex;
  margin-right: 15px;
  font-size: 10px;
  font-weight: 300;
  color: var(--primary-text);
  label {
    display: flex;
    align-items: center;
    span {
      padding-left: 5px;
    }
  }
  .react-switch {
    padding-left: 5px;
    svg {
      display: none;
    }
  }

}

.mode {
  display: flex;
  cursor: pointer;
  .arrow_down {
    width: 15px;
    height: 20px;
    margin-left: 5px;
  }
}
.more_mods {
  position: absolute;
  background-color: var(--module-background);
  border: 1px solid var(--module-border);
  border-radius: 5px;
  top: 25px;
  left: 3px;
  padding: 3px;
  z-index: 100;
  .mode:hover {
    background-color: var(--input-background-hover);
  }
}

@media (max-width: 1020px) {
  .orderBook_options {
    left: calc(100% - 123px);
  }
}