@import "../../../assets/_var.scss";

.card_header_style {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 15px;
  font-size: 14px;
  font-weight: 300;
  color: var(--primary-text);
}

.card_body_style {
  height: calc(100% - 38px);
  overflow: hidden;
}

.cardStyle {
  &.buySellTab {
    overflow: visible !important;
    .react-resizable-handle {
      display: none !important;
    }
    .react-grid-item:hover
    .react-resizable-handle {
      display: none !important;
    }
    .card_header_style{
      display: none;
    }
    .card_body_style {
      height: 100%;
      overflow: visible !important;
    }
  }
  background: var(--module-background);
  border-radius: 15px;
  border: 1px solid var(--module-border);
  overflow: hidden;
  transition: 0.1s;
  ul.card_adjustment_btn {
    width: 60px;
    margin: 5px 11px 0 auto !important;
    position: relative;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    li {
      display: inline-block;
      margin-left: 5px;
      button {
        margin-left: 5px;
        border: 0px;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        background-position: center !important;
        padding: 0;
        &.zoom_out {
        cursor: zoom-out;
        }
        &.zoom_in {
          cursor: zoom-in;
        }
      }
    }
  }
}

.react-grid-layout {
  .react-grid-item {
    .react-resizable-handle {
      opacity: 0;
      &::after {
        content: "";
        position: absolute;
        right: 5px;
        bottom: 5px;
        width: 10px;
        height: 10px;
        border-right: 2px solid var(--secondary-text);
        border-bottom: 2px solid var(--secondary-text);
        cursor: nw-resize;
      }
    }
  }
}
.react-grid-layout
.react-grid-item:hover 
.react-resizable-handle {
  opacity: 1;
}

.head_move {
  cursor: move;
}
.locked {
  pointer-events: none;
  opacity: 0.4;
  z-index: 2;
}

@media (max-width: 1020px) {
  .head_move {
    cursor: auto;
  }
  .react-grid-layout
  .react-grid-item:hover 
  .react-resizable-handle {
    opacity: 0;
    &::after {
      cursor: auto;
    }
  }
}