@import "../../../assets/_var.scss";

.create_wrapper {
  width: 100%;
  min-height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.create_tab {
  width: 480px;
  min-height: 160px;
  margin: 24px 0;
  position: relative;
  background-color: var(--module-background);
  border-radius: 16px;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create_header {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  color: var(--primary-text);
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;

  .back_arrow {
    position: absolute;
    height: 10px;
    left: 10px;
    top: 11px;
    background: url(../../../assets/swap_arrow.svg) no-repeat center;
    border: none;
    transform: rotate(90deg);
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      background: url(../../../assets/swap_arrow_hover.svg) no-repeat center;
    }
  }
}

.assetcard_cont {
  margin-bottom: 16px;
  position: relative;
  width: 100%;
  .switch {
    width: 34px;
    height: 34px;
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 24px;
    background: url(../../../assets/swap_arrow.svg) no-repeat center;
    border: 1px solid var(--module-border);
    z-index: 1;
  }
}

.search_cont {
  width: 100%;
  margin-bottom: 15px;
  position: relative;
  &::before {
    content: url(../../../assets/search.svg);
    position: absolute;
    width: 18px;
    height: 18px;
    top: 12px;
    left: 15px;
  }
  .search {
    width: calc(100% - 40px);
    height: 40px;
    padding-left: 40px;
    background: var(--input-background-default);
    border: 1px solid var(--input-border-default);
    border-radius: 12px;
    font-size: 14px;
    color: var(--primary-text);
    transition: 0.3s;
    &:hover {
      background-color: var(--input-background-hover);
    }
    &:focus {
      outline: 0 !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      border: 1px solid var(--input-border-active);

    }
  }
}

.assets_cont {
  display: block;
  width: 100%;
  max-height: 250px;
  position: absolute;
  top: 50px;
  overflow-y: scroll;
  background: var(--module-background);
  border: 1px solid var(--input-border-default);
  border-radius: 15px;
  box-shadow: 0 3px 10px var(--module-shadow);
  z-index: 100;
  transition: 0.5s;
  li {
    text-align: start;
    padding: 10px;
    border-bottom: 1px solid var(--input-border-default);
    &:hover {
      cursor: pointer;
      background-color: var(--pair-active-row);
    }
    span:nth-child(2){
      color: var(--secondary-text);
      margin-left: 5px;
      font-size: 12px;
    }
  }
  .filter_err {
    display: inline-block;
    color: var(--secondary-text);
    line-height: 22px;
    padding: 10px;
  }
}