@import "../../../assets/_var.scss";

.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal {
  background-color: var(--module-background);
  border: 1px solid var(--module-border);
  border-radius: 16px;
  width: 300px;
  position: relative;
  top: 20%;
  left: calc(50% - 150px);
  padding: 20px;
  button {
    font-weight: 600;
  }
  .modal_header {
    font-size: 16px;
    text-transform: capitalize;
  }
  .modal_body {
    margin-top: 20px;
    .info_text {
      font-size: 12px;
      text-align: center;
    }
    .main_button {
      text-transform: capitalize;
    }
  }
}

.modal_input_cont {
  margin-bottom: 20px;
  .input_wrapper {
    height: 35px;
    border-radius: 16px;
    transform: translateX(0);
    input {
      border-radius: 6px;
      text-align: start;
      padding: 0 10px;
      margin-left: 0;
    }
  }
}

@media (max-width: 580px) {
  .modal {
    width: calc(100vw - 80px);
    left: 20px;
  }
}