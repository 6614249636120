@import "../../../assets/_var.scss";

.faucet_tab {
  width: 470px;
  min-height: 260px;
  padding: 20px;
  background-color: var(--module-background);
  border-radius: 16px;
}

.faucet_header {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 400;
}
.faucet_rules {
  text-align: start;
  margin-bottom: 25px;
  font-size: 16px;
}

.faucet_btn {
  width: 40%;
  border-radius: 10px;
  margin: 0 auto;
  transition: 0.5s;
}

.select_coins {
  width: calc(100% - 30px);
  padding: 15px;
  height: 170px;
  overflow-y: scroll;
  margin-bottom: 10px;
  background: #32334e;
  border-radius: 10px;
  .coin_list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    li {
      box-sizing: border-box;
      width: 110px;
      padding-left: 20px;
      text-align: start;
      background: var(--module-background);
      border-radius: 10px;
      border: 1px solid #f68294;
      .faucet-input {
        position: relative;
        width: 1px;
        height: 1px;
        &::after {
        position: absolute;
        content: "";
        top:-15px;
        left: -13px;
        z-index: 1;
        width: 20px; 
        height: 20px;
        border-radius: 50%;
        border: 1px solid var(--secondary-text);
        background-color: var(--primary-text);
        }
        &:checked {
          &::after {
            position: absolute;
            content: "";
            top:-15px;
            left: -13px;
            z-index: 1;
            width: 20px; 
            height: 20px;
            border-radius: 50%;
            border: 1px solid var(--primary-2);
            background-color: var(--primary-1);
            background-image: url(../../../assets/checkbox.svg);
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        &:focus {
          box-shadow: unset;
          outline: none;
        }
      }
      .faucet-label {
        display: inline-block;
        width: calc(100% - 28px);
        padding: 8px 0 8px 8px;
        font-size: 16px;
      }
    }
  }
}