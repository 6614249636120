@import "../../../../assets/_var.scss";

.tradeForm_notification {
  position: relative;
  top: 80px;
  text-align: center;
  color: var(--secondary-text);
}

.green-shadow {
  box-shadow: 0px 0px 15px 0px var(--buy-text);
  transition: 0.1s;
}
.red-shadow {
  box-shadow: 0px 0px 15px 0px var(--sell-text);
  transition: 0.1s;
}

.tradeForm_tab {
  height: 100%;
  background-color: var(--module-background);
  border-radius: 16px;
}

.tradeForm_draggable {
  height: 25px;
  cursor: move;
}

.tradeForm_side {
  width: 100%;
  height: 40px;
  display: flex;
  border-radius: 15px 15px 0 0;
  overflow: hidden;
  border-bottom: 0;
  background-color: var(--module-background);
  border-bottom: 1px solid var(--input-border-default);
  .nav-link {
    padding: 8px 15px;
    width: 50%;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    border: 0;
    color: var(--primary-text);
    border-radius: 0;
    cursor: pointer;
    &.active {
      background: $baseGradient;
      color: var(--button-text);
      clip-path: polygon(
        93.5% 0%,
        100% 50%,
        93% 100%,
        0% 100%,
        0 49%,
        0% 0%
      );
    }
  }
  #Sell.active {
    clip-path: polygon(6.5% 0%, 100% 0%, 100% 100%, 7.5% 100%, 0% 50%);
  }
}

.tradeForm_form {
  padding: 0 15px 25px;
  cursor: auto;
}

.tradeForm_inputs {
  background: var(--input-background-default);
  border: 1px solid var(--input-border-default);
  border-radius: 10px;
  display: flex;
  height: 35px;
  position: relative;
  margin-bottom: 10px;
  padding: 0 5px;
  .tradeForm_input_group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 5px;
    .input_wrapper {
      transform: translateX(0);
    }
    .input {
      text-align: start !important;
      font-size: 12px;
      margin-left: 0 !important;
    }
    .tradeForm_input_txt {
      color: var(--secondary-text);
      font-size: 12px;
      padding: 0 5px;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  .slash::after {
    content: "";
    position: absolute;
    left: calc(50% - 12px);
    top: 16px;
    height: 1.5px;
    width: 32px;
    -webkit-transform: rotate(290deg);
    transform: rotate(290deg);
    background: transparent linear-gradient(97deg,var(--primary-1) 0,var(--primary-2) 100%);
  }
}

.tradeForm_info_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 12px;
  .total_txt {
    color: var(--secondary-text);
  }
}

@media (max-width: 1020px) {
  .tradeForm_tab {
    margin: 0 10px;
  }
  .tradeForm_notification  {
    position:static;
    padding-bottom: 25px;
  }
}
