@import "../../../assets/_var.scss";

.App_header {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--module-background);
  border-bottom: var(--module-border);
  color: var(--primary-text);
  z-index: 10;
}

.logo {
  min-width: 140px;
  height: 48px;
  background-image: url(../../../assets/logo.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.burger_menu {
  display: flex !important;
  flex-direction: column;
  text-align: start;
  width: 100vw;
  background-color: var(--module-background);
  border-bottom: solid 1px var(--input-background-hover);
  position: absolute;
  top: 49px;
  transition: 0.5s;
  padding-bottom: 5px;
  z-index: 500;
  .link {
    font-size: 16px;
    padding: 0 20px;
    margin-right: 0;
  }
}

.burger_menu_bth {
  height: 0;
}

.link {
  position: relative;
  transition: none !important;
  color: var(--primary-text);
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
  margin-right: 20px;
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    color: var(--hover-element-default) !important;
  }
}

.link_active {
  color: var(--active-or-hover-element) !important;
  &:hover {
    color: var(--active-or-hover-element) !important;
  }
}

@media (max-width: 1020px) {
  .burger_menu_bth {
    display: block;
    min-width: 20px;
    height: 20px;
    margin: 0 10px;
    cursor: pointer;
    background-image: url(../../../assets/burger_menu.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .header_navigation {
    display: none;
  }
}

@media (max-width: 768px) {
  .link {
    font-size: 13px;
  }
}

@media (max-width: 580px) {
  .link {
    font-size: 12px;
    margin-right: 5px;
  }
  .App_header > ul {
    display: flex;
    flex-direction: column;
    text-align: start;
  }
}

@media (max-width: 387px) {
  .App_header {
    width: 100vw;
    height: 60px;
    padding-top: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .header_navigation {
    position: fixed;
  }
}