@import "./src/assets/_var.scss";

body {
  overflow-x: hidden;
  margin: 0;
  color: var(--primary-text);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: "Poppins", sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: var(--main-background);
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

ul {
  padding-left: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  border: none;
  background: transparent;
  color: var(--primary-text);
  cursor: pointer;
}

input {
  border: 0;
  &:focus {
    border: 0;
    outline: none;
  }
}

::-webkit-scrollbar {
  width: 0px !important; 
  background: transparent !important;
}

.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  color: var(--primary-text);
  font-size: 14px;
}

.wrapper {
  position: relative;
  top: 50px;
  min-height: calc(100vh - 90px);
}

.main_button {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $baseGradient;
  color: var(--button-text);
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.5s;
  &:disabled {
    background: var(--button-disabled);
    cursor: auto;
    &:hover {
      filter: none;
    }
  }
  &:hover {
    filter: grayscale(30%) brightness(1.2);
  }
}

.red_btn {
  border: none;
  background: transparent;
  color: var(--active-or-hover-element);
  font-weight: 400;
  padding: 0;
  margin-left: 3px;
  transition: 0.2s;
  &:hover {
    color: var(--hover-element-default);
  }
  &:disabled {
    opacity: 0.5;
    cursor: auto;
    &:hover {
      color: var(--active-or-hover-element);
    }
  }
}

.white_btn {
  width: 50px;
  height: 25px;
  padding: 1px 2px;
  cursor: pointer;
  border: 1px solid var(--primary-text);
  border-radius: 6px;
  font-size: 10px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  transition: all .2s;
  color: var(--primary-text);
  &:hover {
    color: var(--hover-element-default);
    border: 1px solid var(--hover-element-default);
  }
  &:disabled {
    opacity: 0.5;
    cursor: auto;
    &:hover {
      color: var(--primary-text);
      border: 1px solid var(--primary-text);
    }
  }

}

.gray-button {
  &:disabled {
    color: var(--secondary-text);
    cursor: auto;
  }
}

.white_btn_small {
  font-size: 9px;
  width: 50px;
  margin-left: 5px;
}

.WalletIcon {
  margin-right: 5px;
  display: inline-block;
  height: 18px;
  width: 18px;
  background: url(./assets/wallet.svg) no-repeat 5px 4px;
  border-radius: 50%;
  border: 1px solid var(--primary-text);
  cursor: pointer;
}

.arrow_down {
  display: inline-block;
  width: 30px;
  height: 10px;
  background: url(./assets/down_arrow.svg) no-repeat center;
  border: none;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background: url(./assets/down_arrow_hover.svg) no-repeat center;
  }
}

.arrow_down_active {
  background: url(./assets/down_arrow_hover.svg) no-repeat center !important;
}

.arrow_up {
  transform: rotate(180deg);
}

.info_cont {
  background: transparent linear-gradient(97deg,var(--primary-1) 0,var(--primary-2) 100%);
  border-radius: 12px;
  min-height: 36px;
  padding: 1px;
  margin-bottom: 16px;
  .info_text {
    background: var(--module-background);
    border-radius: 12px;
    color: var(--primary-text);
    min-height: 20px;
    padding: 8px 10px;
  }
}


.red-text {
  color: var(--sell-text);
}
.green-text {
  color: var(--buy-text);
}
.red-bg {
  background: var(--sell-text);
}
.green-bd {
  background: var(--buy-text);
}
.odd-row {
  background-color: var(--input-background-default);
  td {
    border-top: 1px solid var(--input-background-default);
  }
}

.unvisible {
  pointer-events: none;
  visibility: hidden;
}

.disabled {
  opacity: 0.5;
  pointer-events: none !important;
  cursor: auto !important;
}

.flex_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.flex_column {
  flex-direction: column;
}

.text-end {
  text-align: end;
}

.scroll {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px !important;
    opacity: 0 !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--input-border-default) !important;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
    background-color:  var(--module-background) !important;
  }
}