.input_wrapper {
  display: flex;
  transform: translateX(10px);
  height: 20px;
  width: 100%;
}

.input {
  width: 100%;
  min-width: 30px;
  background: var(--input-background-default);
  margin-left: 10px;
  color: var(--primary-text);
  font-size: 14px;
  text-align: end;
}

.input_btns {
  margin-left: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  .trade_arrow_down {
    height: 15px;
    width: 10px;
    background: url(../../../assets/trade_arrow.svg) no-repeat center;
    border: none;
    cursor: pointer;
    transition: 0.2s;
    transform: rotate(90deg);
    padding: 0;
    &:hover {
      background: url(../../../assets/trade_arrow_hover.svg) no-repeat center;
    }
  }

  .trade_arrow_up {
    transform: rotate(-90deg);
  }
}
