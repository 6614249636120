.walletInfo_tab {
  font-size: 12px;
}

.walletName {
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--primary-text);
  text-align: start;
  cursor: pointer;
}

.walletInfo_head {
  font-size: 11px;
  font-weight: 400;
  padding: 5px 15px;
  color: var(--secondary-text);
  border-bottom: 1px solid var(--input-border-default);
}

.walletInfo_input {
  width: calc(100% - 20px);
  height: 30px;
  margin-bottom: 20px;
  padding-left: 8px;
  background: var(--input-background-default);
  color: var(--primary-text);
  border: 1px solid var(--primary-text);
  border-radius: 12px;
  transition: 0.3s;
  &:hover {
    background-color: var(--input-background-hover);
  }
  &:focus {
    outline: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid var(--input-border-active);
  }
}

.buttons_cont {
  display: flex;
  justify-content: flex-end;
  column-gap: 5px;
  padding-right: 15px !important;
}

.grid {
  text-align: start;
  display: grid;
  row-gap: 5px;
  span {
    padding: 5px 15px;
  }
}

.grid_balances {
  min-height: 100px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 30px 25px;
  border-bottom: 1px solid var(--input-border-default);
  padding-bottom: 20px;
  h6, span{
    line-height: 15px;
    overflow: hidden;
  }
}

.grid_approwed {
  grid-template-columns: 0.1fr 1fr 1fr;
  grid-template-rows: 35px 30px 30px;
  border-bottom: 1px solid var(--input-border-default);
  padding-bottom: 20px;
  h6 , span {
    line-height: 25px;
  }
}

.grid_locked {
  grid-template-columns: 0.5fr 0.5fr 0.5fr 4fr;
  grid-template-rows: 35px 30px 30px;
  padding-bottom: 10px;
  h6, span {
    line-height: 25px;
    padding-right: 0;
  }
}