@import "../../../assets/_var.scss";

.connect_wallet {
  display: flex;
  align-items: center;
  height: 34px;
  padding: 0 10px;
  background: $baseGradient;
  color: var(--button-text);
  border-radius: 8px;
}