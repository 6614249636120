
.range_innr {
  margin: -20px 10px 20px;
  .wrap_range{
    height: 40px;
  }
  &.disabled {
    opacity: 0.5;
  }
}

.tumbs {
  margin-top: 3px !important;
}