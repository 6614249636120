@import "../../../../assets/_var.scss";

.wallet_cont {
  color: var(--primary-text);
  min-width: 150px;
  padding: 0 4px;
}

.wallet_balance {
  display: inline-block;
  width: 140px;
  height: 24px;
  padding: 2px 8px;
  margin-right: 10px;
  line-height: 25px;
  background: var(--module-background);
  border-radius: 8px;
  color: var(--primary-text);
  overflow: hidden;
}

.wallet_address {
  display: inline-block;
  width: 125px;
  margin-right: 10px;
}

.disconnect_wallet {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url(../../../../assets/exit.svg) no-repeat center;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    transform: scale(1.2);
  }
}

@media (max-width: 590px) {
  .wallet_balance {
    width: auto;
    font-size: 12px;
  }
  .wallet_address {
    width: auto;
    font-size: 12px;
    margin-right: 0;
  }
}